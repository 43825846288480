import {coerceLogLevel} from '../helpers';
import {LogLevel, type LogHandler} from '../interfaces';

type ConsoleLogHandlerConfig = {
  logLevel?: LogLevel | string;
  logToConsole?: boolean;
  prefix?: string;
};

export class ConsoleLogHandler implements LogHandler {
  public logLevel: LogLevel = LogLevel.UNKNOWN;
  private readonly logToConsole: boolean = true;
  private readonly prefix: string = '[DREAMFLARE]';

  constructor(config: ConsoleLogHandlerConfig = {}) {
    if (
      config.logLevel !== undefined &&
      Object.values(LogLevel).includes(config.logLevel)
    ) {
      this.setLogLevel(config.logLevel);
    }

    this.logToConsole = config.logToConsole ?? this.logToConsole;
    this.prefix = config.prefix ?? this.prefix;
  }

  log(level: LogLevel, message: string): void {
    if (!this.shouldLog(level) || !this.logToConsole) {
      return;
    }

    const logMessage = [
      this.prefix,
      '-',
      this.getLogLevelName(level),
      this.getTime(),
      message,
    ].join(' ');

    this.consoleLog(level, [logMessage]);
  }

  setLogLevel(level: LogLevel | string): void {
    level = coerceLogLevel(level);
    if (!Object.values(LogLevel).includes(level)) {
      this.logLevel = LogLevel.ERROR;
    } else {
      this.logLevel = level;
    }
  }

  getTime(): string {
    return new Date().toISOString();
  }

  private shouldLog(targetLogLevel: LogLevel): boolean {
    return targetLogLevel >= this.logLevel;
  }

  private getLogLevelName(logLevel: LogLevel): string {
    switch (logLevel) {
      case LogLevel.DEBUG:
        return 'DEBUG';
      case LogLevel.INFO:
        return 'INFO ';
      case LogLevel.WARNING:
        return 'WARN ';
      case LogLevel.ERROR:
        return 'ERROR';
      default:
        return 'UNKNOWN';
    }
  }

  private consoleLog(logLevel: LogLevel, logArguments: [string, ...string[]]) {
    switch (logLevel) {
      case LogLevel.DEBUG:
        // eslint-disable-next-line no-console
        console.log(...logArguments);
        break;
      case LogLevel.INFO:
        // eslint-disable-next-line no-console
        console.info(...logArguments);
        break;
      case LogLevel.WARNING:
        // eslint-disable-next-line no-console
        console.warn(...logArguments);
        break;
      case LogLevel.ERROR:
        // eslint-disable-next-line no-console
        console.error(...logArguments);
        break;
      default:
        // eslint-disable-next-line no-console
        console.log(...logArguments);
    }
  }
}
